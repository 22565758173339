import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { RegistrationsContext } from "../../context/RegistrationsContext"
import { extractChartData, filterAndCombine, filterTokens, formatNumber, getDataTotal } from "../../util"
import { BarGraphCard } from "../GraphCard"

import "./RegistrationsChart.css"

const RegistrationsChart: React.FC = () => {
	const { registrations, loading } = useContext(RegistrationsContext)
	const { totalDashboards, totalSwaps, totalSelected } = useContext(FilterContext)

	const totalRegistrations = getDataTotal(filterAndCombine(registrations, totalSelected), "registrations")
	const chartData = extractChartData(filterAndCombine(registrations, totalSelected), "registrations", "date")

	return (
		<BarGraphCard
			xKey="date"
			className="registration-graph"
			data={chartData}
			title="FTD"
			keys={{dashboards: totalDashboards, swaps: totalSwaps}}
			figureText={formatNumber(totalRegistrations)}
			loading={loading}
		/>
	)
}

export default RegistrationsChart